import { createUseQuery } from "eddev/hooks";
import {
  UseAccountantIndustriesQuery,
  UseAccountantsQuery,
  UseAccountantsQueryVariables,
  UseArticlesQuery,
  UseArticlesQueryVariables,
  UseBlogPostCategoriesQuery,
  UseEventCategoriesQuery,
  UseEventsQuery,
  UseEventsQueryVariables,
  UseFormQuery,
  UseFormQueryVariables,
  UsePageQuery,
  UsePageQueryVariables,
} from "../types.graphql";

/**
 * DO NOT EDIT
 * This file is automatically generated by eddev
 * Any changes will be overwritten
 */

export const useAccountantIndustries = createUseQuery<UseAccountantIndustriesQuery, undefined>({
  name: "UseAccountantIndustries",
});

export const useAccountants = createUseQuery<UseAccountantsQuery, UseAccountantsQueryVariables>({
  name: "UseAccountants",
});

export const useArticles = createUseQuery<UseArticlesQuery, UseArticlesQueryVariables>({ name: "UseArticles" });

export const useBlogPostCategories = createUseQuery<UseBlogPostCategoriesQuery, undefined>({
  name: "UseBlogPostCategories",
});

export const useEventCategories = createUseQuery<UseEventCategoriesQuery, undefined>({ name: "UseEventCategories" });

export const useEvents = createUseQuery<UseEventsQuery, UseEventsQueryVariables>({ name: "UseEvents" });

export const useForm = createUseQuery<UseFormQuery, UseFormQueryVariables>({ name: "UseForm" });

export const usePage = createUseQuery<UsePageQuery, UsePageQueryVariables>({ name: "UsePage" });
